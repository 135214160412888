import React from 'react'
import "./navbar.css"
const Navbar = () => {
  return (
    <div className='navbarContainer' style={{height:"40px", width:"100%"}}>
        <div style={{fontFamily:"Poppins"}}>🌟 Limited-Time Offer: Unwrap the Savings! 🎁</div>
    </div>
  )
}

export default Navbar