import React from 'react'
import {Box, Flex, Grid, GridItem, Heading, Image, Spacer, Text} from "@chakra-ui/react"
import photo from "./../../assets/arrow.png"
import photo1 from "./../../assets/Vector.png"
import photo2 from "./../../assets/business_center.png"
const RecommendedData = () => {
  return (
    <Box h={["auto","auto","300px"]} w={["100%","100%","100%"]}   color={"white"} pt={["25px","25px",""]} pb={["25px","25px",""]} bg={"#FFF0F3"}>
    <Flex h={["100%","100%","100%"]} w={["100%","100%","100%"]} alignItems={["center","center","center"]} justifyContent={["center","center","center"]} flexDirection={["column","column","row"]}>
        <Box h={["auto","auto","90%"]} w={["85","85","75%"]} >
        <Grid h={"100%"} templateColumns={['repeat(1, 1fr)',"repeat(1, 1fr)",'repeat(3, 1fr)']} gap={7} textAlign={['center','center','center']}>
            <GridItem w={["90%","85%",'100%']} h='100%' m={"auto"} >
                  <Flex h={"20%"} w={"100%"} alignItems={"center"} justifyContent={"center"} mb={"10px"}>
                  <   Image src={photo} h={"10" } w={"10"}/>
                  </Flex>
                 
                  <Heading as='h4' h={"50px"} w={"50px"} borderRadius={"100%"} m={"auto"} p={"10px"} alignItems={"center"} size='md' bg={"#FFB2B2"}>01</Heading>
                  <Heading as='h4' size='md' color={"#222222"} mt={"10px"} mb={"10px"} fontFamily={"poppins"}>Contact Us</Heading>
                  <Text color={"#666666"} fontFamily={"poppins"}>Join Our Affiliate Program Today! 
                        Partner with us to spread the romance
                        and earn exciting rewards. It's time to turn
                        passion into profits</Text>
                 
            </GridItem>
            <GridItem w={["90%","85%",'100%']} h='100%' m={"auto"} >
            <Flex h={"20%"} w={"100%"} alignItems={"center"} justifyContent={"center"} mb={"10px"}>
                  <   Image src={photo1} h={"10" } w={"10"}/>
                  </Flex>
                
                  <Heading as='h4' h={"50px"} w={"50px"} p={"10px"} borderRadius={"100%"} m={"auto"} alignItems={"center"} size='md' bg={"#FFB2B2"}>02</Heading>
                  <Heading as='h4' size='md' color={"#222222"} mt={"10px"} mb={"10px"} fontFamily={"poppins"}>Recommend</Heading>
                  <Text fontFamily={"poppins"} color={"#666666"}>Join Our Affiliate Program Today! 
                        Partner with us to spread the romance
                        and earn exciting rewards. It's time to turn
                        passion into profits</Text>
            </GridItem>
            <GridItem w={["90%","85%",'100%']} h='100%' m={"auto"}   >
            <Flex h={"20%"} w={"100%"} alignItems={"center"} justifyContent={"center"} mb={"10px"}>
                  <   Image src={photo2} h={"10" } w={"10"}/>
                  </Flex>
                 
                  <Heading as='h4' h={"50px"} w={"50px"} p={"10px"} borderRadius={"100%"} m={"auto"} alignItems={"center"} size='md' bg={"#FFB2B2"}>03</Heading>
                  <Heading as='h4' size='md' color={"#222222"} mt={"10px"} mb={"10px"} fontFamily={"poppins"}>Earn</Heading>
                  <Text color={"#666666"} fontFamily={"poppins"}>Join Our Affiliate Program Today! 
                        Partner with us to spread the romance
                        and earn exciting rewards. It's time to turn
                        passion into profits</Text>
            </GridItem>
         
            
        </Grid>
        </Box>
    </Flex>
</Box>
  )
}

export default RecommendedData