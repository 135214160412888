import React from 'react'
import { Box, Button, Flex, Heading, Image, Input, Spacer, Text } from '@chakra-ui/react'
import photo from "./../../assets/bricks.png"
import { useForm, ValidationError } from '@formspree/react';
const ContactForm = () => {
    const [state, handleSubmit] = useForm("mzbneagd");
  return (
    <Box h={['auto','700px','550px']} w={['100%','100%','100%']} bg={"#FFFFFF"} >
    <Flex h={['100%','100%','100%']} w={['100%','100%','100%']} flexDirection={["column","column","row"]} alignItems={["center","center",""]} justifyContent={["center","center",""]} >
      
       
        <Box h={['55%','55%','100%']} w={["100%","100%",'55%']}  >
           <Flex h={['100%','100%','100%']} w={['100%','100%','100%']} alignItems={["center","center","center"]} flexDirection={["row","row","row"]} justifyContent={["center","center","center"]}>
              <Box h={["95%","95%","85%"]} w={["97%","95%","70%"]}>
                    <Box h={"100%"} w={["95%","90%","80%"]} textAlign={["center","",""]}>
                       
                        <Text mb={["15px","15px","20px"]} mt={["20px","20px",""]} color={"#FF7878"} fontFamily={"poppins"}>Fill This Form And Let Us Know Why You Want To Join Our Affiliate Program
                           </Text>
                       
                        <Box w={"100%"}>
                        <form onSubmit={handleSubmit}>
                            <Flex w={"100%"}>
                                <Input name='firstName' fontFamily={"poppins"} variant='flushed' placeholder='First Name' mb={"15px"} w={"49%"}/>
                                <Spacer/>
                                <Input name='lastName' fontFamily={"poppins"} variant='flushed' placeholder='Last Name' mb={"15px"} w={"49%"}/>
                            </Flex>
                           
                            <Input name='mail' fontFamily={"poppins"} variant='flushed' placeholder='Mail' mb={"15px"} />
                            <Input name='number' fontFamily={"poppins"} variant='flushed' placeholder='Contact Number' mb={"15px"} />
                            <Input name='Reason' fontFamily={"poppins"} variant='flushed' placeholder='Reason To Join affiliate program ?' mb={"35px"} />
                             <Flex alignItems={["left","",""]}>
                             <Button bg={"#A70808"} size='sm' color={"white"} fontFamily={"poppins"} type='submit'>
                                Submit
                            </Button>
                             </Flex>
                            </form>
                        </Box>
                        <Text size={"sm"} mt={["15px","15px","30px"]} color={"#666666"} fontSize={"14px"} fontFamily={"poppins"} mb={["15px","15px",""]} textAlign={["left","left",""]}>For Any Doubt Or Any Enquiry Please Feel Free To Contact Us</Text>
                    </Box> 
                   
                      
              </Box>
           </Flex>
             
          
        </Box>
        <Box h={['45%',"45%",'100%']} w={['100%',"",'45%']}display={'flex'} justifyContent={'space-around'} alignItems={'center'} >
        <Image
            objectFit='cover'
            h={"100%"}
            w={"100%"}
            src={photo}
            alt='Caffe Latte'
            />
        </Box>
    </Flex>
</Box>
  )
}

export default ContactForm;