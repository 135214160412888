import React from 'react'
import {Box, Flex, Grid, GridItem, Heading, Image, Spacer, Text} from "@chakra-ui/react"
import photo from "./../../assets/Gpay.png"
import photo1 from "./../../assets/Rzpay.png"
const Footer1 = () => {
  return (
    <Box h={["auto","auto","250px"]} w={["100%","100%","100%"]}  bg={"#DF4646"} color={"white"}>
        <Flex h={["100%","100%","100%"]} w={["100%","100%","100%"]} alignItems={["center","center","center"]} justifyContent={["center","center","center"]}>
            <Box h={["90%","90%","75%"]} w={["98%","85%","75%"]}>
            <Grid h={["100%","100%","100%"]} templateColumns={['repeat(2, 1fr)',"repeat(2, 1fr)",'repeat(4, 1fr)']} gap={["1","5","6"]} textAlign={'left'}  >
                <GridItem w={["100%","90%","100%"]} h={["100%","100%","100%"]}>
                     <Heading as='h4' size={["sm","",'md']} mt={"20px"} mb={["15px","","20px"]} fontFamily={"Poppins"}>ABOUT US</Heading>
                     <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]}>+123 23 145 </Text>
                     <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]}>abc123@gmail.com</Text>
                     <Text fontFamily={"Poppins"} w={"65%"} fontSize={["15px","","17px"]}>Founded and address of the brand</Text>
                </GridItem>
                <GridItem w={["100%","100%","100%"]} h={["100%","100%","100%"]} >
                    <Heading as='h4' size={["sm","",'md']} mt={"20px"} mb={["15px","","20px"]} fontFamily={"Poppins"}>CUSTOMER CARE</Heading>
                    <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]}>Shipping and delivery </Text>
                     <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]}>Terms and conditions</Text>
                     <Text fontFamily={"Poppins"} w={"65%"} fontSize={["15px","","17px"]}>Privacy and policy</Text>
                     <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]}>Return policy</Text>
                     <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]}>FAQ</Text>
                </GridItem>
                <GridItem w={["100%","100%","100%"]} h={["100%","100%","100%"]}   >
                    <Heading as='h4' size={["sm","",'md']} mt={"20px"} mb={["15px","","20px"]} fontFamily={"Poppins"}>Follow Us</Heading>
                    <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]}>For exclusive news and updates</Text>
                    <Flex h={["","auto",""]} w={["","80%"]} justifyContent={["","space-between",""]}>
                        <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]} cursor={"pointer"}>Instagram</Text>
                        <Spacer/>
                        <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]} cursor={"pointer"}>Facebook</Text>
                        <Spacer/>
                        <Text fontFamily={"Poppins"} fontSize={["15px","","17px"]} cursor={"pointer"}>Pinterest</Text>
                    </Flex>
                </GridItem>
                <GridItem w={["100%","100%","100%"]} h={["100%","100%","100%"]} >
                    <Box mt={"20px"} mb={["15px","","20px"]}>
                    <Image src={photo} h={["7","","10"]} w={"auto"}/>
                    <Image src={photo1}  h={["8","","14"]} w={"auto"} mt={"10px"}/>
                    </Box>
                   

                </GridItem>
                
            </Grid>
            </Box>
        </Flex>
    </Box>
  )
}

export default Footer1