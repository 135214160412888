import React from 'react'
import {Box, Image} from "@chakra-ui/react"
import photo from "./../../assets/affilite.png"
const Affiliate = () => {
  return (
    <Box h={["300px","350px","350px"]} w={["100%","100%","100%"]} >
        <Image
              
                h={"100%"}
                w={"100%"}
                src={photo}
                alt='Caffe Latte'
                />
    </Box>
  )
}

export default Affiliate