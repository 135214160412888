import React from 'react'
import { Box, Button, Flex, Heading, Image, Input, Spacer, Text } from '@chakra-ui/react'
import photo from "./../../assets/perfume.png"
import { useForm, ValidationError } from '@formspree/react';
const Contact = () => {
    const [state, handleSubmit] = useForm("mzbneagd");
 
  return (
    <Box h={['720px','850px','600px']} w={['100%','100%','100%']} bg={"#FFFFFF"} id='contact' >
    <Flex h={['100%','100%','100%']} w={['100%','100%','100%']} flexDirection={['column','column','row']}>
        <Box h={['45%','55%','100%']} w={['100%','100%','45%']}display={['','','flex']} justifyContent={'space-around'} alignItems={'center'} >
        <Image
            objectFit='cover'
            h={"100%"}
            w={"100%"}
            src={photo}
            alt='Caffe Latte'
            />
        </Box>
       
        <Box h={['55%','45%','100%']} w={['100%','100%','55%']}  >
           <Flex h={["100%","100%","100%"]} w={["100%","100%","100%"]} alignItems={["center","center","center"]} justifyContent={["center","center","center"]}>
              <Box h={["85%","85%","85%"]} w={["85%","85%","85%"]} >
                    <Box h={["100%","100%","100%"]} w={["100%","100%","85%"]}>
                        <Heading as='h2' size='xl' mt={["0px","0px","20px"]} mb={["5px","10px","20px"]} fontWeight={"none"}  letterSpacing={"8px"} fontFamily={"Moniqa"}>CONTACT  <span style={{color:"#DF4646"}}> US</span></Heading>
                        <Text mb={["10px","15px","20px"]} color={"#222222"} fontFamily={"Poppins"} fontSize={["15px","17px","17px"]}>Our Cupid crew is here to make your Valentine's Day unforgettable.Let's chat,
                            create, and spread the love together!</Text>
                        <Text mb={["5px","8px","20px"]} fontSize={["15px","17px","17px"]} fontFamily={"Poppins"} color={"#222222"}>Number: 7420948534   <span style={{marginLeft:"40px"}}>E-Mail: disldosti108@gmail.com</span> </Text>
                        <Box>
                        <form onSubmit={handleSubmit}>
                            <Input variant='flushed' name="userName" placeholder='Name' mb={["10px","10px","15px"]}  fontFamily={"Poppins"}  />
                            <Input variant='flushed' name='mail' placeholder='Mail' mb={["10px","10px","15px"]}  fontFamily={"Poppins"}  />
                            <Input variant='flushed' name='message' placeholder="Message" mb={["15px","10px","15px"]}   fontFamily={"Poppins"} />
                            <Flex h={["auto","auto","auto"]} w={['100%',"100%","100%"]} justifyContent={["space-around","",""]} alignItems={["center","center","center"]}>
                            <Button bg={"#A70808"}  fontFamily={"Poppins"}  fontWeight={"none"} size='sm' color={"white"} cursor={"pointer"} type='submit' >
                                Send Us
                            </Button>
                            </Flex>
                            
                            </form>
                        </Box>
                    </Box> 
                   
                      
              </Box>
           </Flex>
             
          
        </Box>
    </Flex>
</Box>
  )
}

export default Contact