import React from 'react'
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import photo from "./../../assets/about.png"
const About = () => {
  return (
    <Box h={["480px","550px","600px"]} w={["100%","100%","100%"]} id='about'  >
        
    <Flex h={["85%","85%","85%"]} w={['100%','100%','100%']} justifyContent={['center','center','center']} alignItems={['center','center','center']}  >
        <Box h={'100%'} w={'100%'} >
            <Box h={["85%","85%","75%"]} w={"100%"} textAlign={'center'} mt={"20px"}>
               
                <Heading as='h2' size='xl' fontFamily={"Moniqa"} fontWeight={"none"}  letterSpacing={"8px"}>ABOUT <span style={{color:"#DF4646"}}>US</span></Heading>
                <Box w={["100%","100%","75%"]} h={["60%","70%","80%"]} m={"auto"} mt={["25px","25px","30px"]} mb={["10px","10px","10px"]}   >
                <Image
                
                h={"100%"}
                w={"100%"}
                src={photo}
                alt='Caffe Latte'
                />
                </Box>
                <Box w={["100%","100%","75%"]} h={["40%","35%","30%"]} m={"auto"} mt={["25px","25px","25px"]} mb={["0px","0px","0"]} >
                    <Flex w={["100%","100%","100%"]} h={["100%","100%","100%"]} flexDirection={["column","column","row"]} >
                        <Box h={["auto","100%","100%"]} w={["100%","100%","70%"]} borderRight={["none","none","3px solid #FFB2B2"]}  >
                           <Flex h={["auto","100%","100%"]} w={["100%","100%","65%"]} alignItems={["center","center","center"]} justifyContent={["center","center",""]} >
                           <Text w={["90%","80%",""]} borderTop={["3px solid #FFB2B2","3px solid #FFB2B2","none"]} fontSize={["12px","14px","17px"]} textAlign={["center","center","left"]} fontFamily={"Poppins"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum fugiat enim, culpa molestias atque fuga perspiciatis asperiores nemo vel exercitationem odit consequuntur quos a facilis numquam cumque error accusantium! Inventore?</Text>
                            </Flex> 
                        </Box>
                        <Box w={["100%","100%","30%"]} h={["auto","auto","100%"]} >
                        <Flex h={["auto","auto","100%"]} w={["100%","100%","80%"]} alignItems={"center"} justifyContent={'center'} mt={["20px","",""]}>
                            <Heading fontFamily={"Montserrat"} fontWeight={"none"} textAlign={["center","center","center"]}>Company Name</Heading>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            </Box>
           
        </Box>
       
    </Flex>
   
</Box>
  )
}

export default About