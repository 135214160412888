import logo from './logo.svg';
import './App.css';
import MainRouter from './Components/Router/MainRouter';
import Valentine from './Components/Valentine';
import Navbar from './Components/Navbar/Navbar';
import MainNav from './Components/Navbar/MainNav';

function App() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <div>
     {/* <Valentine/> */}
     <Navbar/>
     <MainNav scrollToSection={scrollToSection}/>
     <MainRouter/>
    </div>
  );
}

export default App;
