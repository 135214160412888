import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import photo1 from "./../../assets/gift1.png"
import photo2 from "./../../assets/gift2.png"
import photo3 from "./../../assets/gift3.png"
import photo4 from "./../../assets/gift4.png"
import photo5 from "./../../assets/gift5.png"
const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    className: "center",
    centerMode: true,
    
    speed: 1000, 
    autoplay: true,
    autoplaySpeed: 1000, 
    cssEase: "linear",
    pauseOnHover: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
   
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div style={{height:"70%"}}>
          <img src={photo1} alt="Image 1" style={{ Width: "25%", height: "250px" }} />
        </div>
        <div style={{height:"70%"}}>
          <img src={photo2} alt="Image 2" sstyle={{ Width: "25%", height: "250px" }} />
        </div>
        <div style={{height:"70%"}}>
          <img src={photo3} alt="Image 3" style={{ Width: "25%", height: "250px" }} />
        </div>
        <div style={{height:"70%"}}>
          <img src={photo4} alt="Image 2" style={{ Width: "25%", height: "250px" }} />
        </div>
        <div style={{height:"70%"}}>
          <img src={photo5} alt="Image 3" style={{ Width: "25%", height: "250px" }} />
        </div>
        {/* Add more images here */}
      </Slider>
    </div>
  );
};

export default ImageSlider;
