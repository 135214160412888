import React from 'react'
import {Routes,Route} from "react-router-dom"
import Valentine from '../Valentine'
import Desktop from '../Desktop'
import CenterMode from '../LandingPage/Slick'

const MainRouter = () => {
  return (
    <Routes>
        <Route path='/' element={<Valentine/>}/>
        <Route path='/a' element={<CenterMode/>}/>
        <Route path='/desktop' element={<Desktop/>}/>
    </Routes>
  )
}

export default MainRouter