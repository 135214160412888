// import React from 'react'
// import {Flex,Box,Heading,Spacer, ButtonGroup, Button, Text, Center} from "@chakra-ui/react"
// import { useNavigate } from 'react-router-dom'
// const MainNav = ({scrollToSection}) => {
//   const navigate=useNavigate()
//   return (
//     <Box h={"60px"} w={"100%"}>
//       <Box h={"100%" } w={'95%'} m={'auto'} >
//         <Flex h={"100%"} w={"100%"} alignItems='center'  gap='2' >
//           <Box p='2'>
//             <Heading size='lg' as={'h4'} color={"#858484"} fontFamily={"Montserrat"} onClick={()=>{navigate("/")}} cursor={"pointer"}>LOGO</Heading>
//           </Box>
//           <Spacer />
//           <ButtonGroup gap='2' >
//             <Button  color={"#666666"} bg={"none"} fontFamily={"Poppins"} onClick={()=>{scrollToSection('brand')}}>Our Brands</Button>
//             <Button color={"#666666"} bg={"none"} fontFamily={"Poppins"} onClick={()=>{scrollToSection('about')}}>About</Button>
//             <Button color={"#666666"} bg={"none"}  fontFamily={"Poppins"} onClick={()=>{scrollToSection('contact')}}>Contact Us</Button>
//             <Button color={"white"} bg={"#A70808"} fontFamily={"Poppins"} onClick={()=>{navigate("/desktop")}}>Be an Affiliate</Button>
//           </ButtonGroup>
//         </Flex>
//       </Box>
//       </Box>
//   )
// }

// export default MainNav
import React, { useState } from 'react';
import {
  Flex,
  Box,
  Heading,
  Spacer,
  ButtonGroup,
  Button,
  Text,
  Center,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  IconButton,
  HStack,
  Image,
  
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa'
import photo from "./../../assets/dildosti.jpg"

const MainNav = ({ scrollToSection }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Box h={'50px'} w={'100%'}>
      <Box h={'100%'} w={'95%'} m={'auto'}>
        <Flex h={'100%'} w={'100%'} alignItems='center' gap='2'>
          <Box h={"100%"} w={"8%"} p='0'>
            {/* <Heading
              size='lg'
              as={'h4'}
              color={'#858484'}
              fontFamily={'Montserrat'}
              onClick={() => {
                navigate('/');
              }}
              cursor={'pointer'}>
              LOGO
            </Heading> */}
            <Image src={photo} h="100%" width={"100%"} />
          </Box>
          <Spacer />
          <Flex display={['none', 'none', 'flex']} alignItems='center'>
            <ButtonGroup gap='2'>
              <Button color={'#666666'} bg={'none'} fontFamily={'Poppins'} onClick={() => scrollToSection('brand')}>
                Our Brands
              </Button>
              <Button color={'#666666'} bg={'none'} fontFamily={'Poppins'} onClick={() => scrollToSection('about')}>
                About
              </Button>
              <Button
                color={'#666666'}
                bg={'none'}
                fontFamily={'Poppins'}
                onClick={() => scrollToSection('contact')}>
                Contact Us
              </Button>
              <Button color={'white'} bg={'#A70808'} fontFamily={'Poppins'} onClick={() => navigate('/desktop')}>
                Be an Affiliate
              </Button>
            </ButtonGroup>
          </Flex>
          <IconButton
            ref={btnRef}
            display={['flex', 'flex', 'none']}
            aria-label='Open menu'
            icon={<HamburgerIcon />}
            onClick={onOpen}
          />
        </Flex>
      </Box>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={btnRef} >
        <DrawerOverlay>
          <DrawerContent  style={{ height: '30vh', width:"60%"}}>
            <DrawerCloseButton  color={"#A70808"}/>
            
            <DrawerBody>
              <ButtonGroup flexDir='column' justifyContent='flex-start'>
                <Button
                  color={'#A70808'}
                  bg={'none'}
                  fontWeight={"none"}
                  fontFamily={'Poppins'}
                  onClick={() => {
                    scrollToSection('brand');
                    onClose();
                  }}>
                  Our Brands
                </Button>
                <Button
                  color={'#A70808'}
                  bg={'none'}
                  fontWeight={"none"}
                  fontFamily={'Poppins'}
                  onClick={() => {
                    scrollToSection('about');
                    onClose();
                  }}>
                  About
                </Button>
                <Button
                 color={'#A70808'}
                  bg={'none'}
                  fontWeight={"none"}
                  fontFamily={'Poppins'}
                  onClick={() => {
                    scrollToSection('contact');
                    onClose();
                  }}>
                  Contact Us
                </Button>
               
              </ButtonGroup>
              <HStack mt={1} mb={2}>
                <IconButton
                  as='a'
                  href='#'
                  aria-label='Twitter'
                  icon={<FaTwitter />}
                  colorScheme='twitter'
                  size='md'
                />
                <IconButton
                  as='a'
                  href='#'
                  aria-label='YouTube'
                  icon={<FaYoutube />}
                  colorScheme='red'
                  size='md'
                />
                <IconButton
                  as='a'
                  href='#'
                  aria-label='Instagram'
                  icon={<FaInstagram />}
                  colorScheme='pink'
                  size='md'
                />
              </HStack>
              <Text color={'#A70808'} fontSize={"12px"}>Number :+91 7420948534</Text>
              <Text color={'#A70808'} fontSize={"12px"}>Email : disldosti108@gmail.com</Text>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default MainNav;
