import React from 'react'
import Affiliate from './LandingPage/Affiliate'
import News from "./LandingPage/News"
import Footer from "./LandingPage/Footer"
import ContactForm from './DeskTop/ContactForm'
import RecommendedData from './DeskTop/RecommendedData'
const Desktop = () => {
  return (
    <div>
       <div>
        <Affiliate/>
       </div>
       <RecommendedData/>
       <div>
        <ContactForm/>
       </div>
       <div>
        <News/>
       </div>
       <div>
        <Footer/>
       </div>
    </div>
  )
}

export default Desktop