import React from 'react'
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import photo from "./../../assets/gift.png"
const Guidance = () => {
  return (
    <Box h={["650px","650px","650px"]} w={["100%","100%","100%"]}  >
        
    <Flex h={["85%","85%","85%"]} w={['100%','100%','100%']} justifyContent={['center','center','center']} alignItems={['center','center','center']}  >
        <Box h={'100%'} w={'100%'} >
            <Box h={"75%"} w={"100%"} textAlign={'center'} >
               
                <Text w={['93%','90%','46%']} h={'auto'} margin={'auto'} mt={'20px'} mb={['10px','15px','20px']} color={"#222222"} fontFamily={"Poppins"} fontSize={["15px","17px","17px"]} >
                Connect with our Cupid Consultants! Explore perfect gifts for each day of Valentine's Week. 
                Click below to chat on WhatsApp and let our experts guide you to the most memorable surprises. 
                Elevate your celebration with thoughtful choices every day!
                </Text>
                <Box w={["100%","100%","75%"]} h={["90%","90%","90%"]} m={"auto"} mt={["25px","30px","40px"]} mb={["10px","15px","20px"]}   >
                <Image
                objectFit='cover'
                h={"100%"}
                w={"100%"}
                src={photo}
                alt='Caffe Latte'
                />
                </Box>
            </Box>
           
        </Box>
       
    </Flex>
    <Heading as='h2' size={['xl',"2xl",'2xl']} textAlign={'center'} fontFamily={"Montserrat"}>
                <span style={{color:"#FF7878"}}>emembered.</span>love delivered gifts remembered . 
            </Heading>
</Box>
  )
}

export default Guidance