import React from 'react'
import { Box, Flex, Grid, GridItem, Heading, Image, Text } from '@chakra-ui/react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import photo1 from "./../../assets/gift1.png"
import photo2 from "./../../assets/gift2.png"
import photo3 from "./../../assets/gift3.png"
import photo4 from "./../../assets/gift4.png"
import photo5 from "./../../assets/gift5.png"
// const Featured = () => {
//   return (
//     <Box h={["650px","650px","450px"]} w={["100%","100%","100%"]} bg={"#FFF0F3"} >
        
//     <Flex h={["85%","85%","85%"]} w={['100%','100%','100%']} justifyContent={['center','center','center']} alignItems={['center','center','center']}  >
//         <Box h={'100%'} w={'100%'} >
//             <Box h={"75%"} w={"100%"} textAlign={'center'} >
               
//                 <Text w={['93%','90%','46%']} h={'auto'} margin={'auto'} mt={'20px'} mb={['10px','15px','20px']} color={"#DF4646"} fontFamily={"Poppins"} fontSize={["15px","17px","17px"]} >
//                 To be featured tag us @abcdxyz
//                 </Text>
//                 <Box w={["100%","100%","99%"]} h={["90%","90%","100%"]} m={"auto"} mt={["25px","30px","40px"]} mb={["10px","15px","20px"]}   >
//                 {/* <Image
//                 objectFit='cover'
//                 h={"100%"}
//                 w={"100%"}
//                 src={photo}
//                 alt='Caffe Latte'
//                 /> */}
//                  <Grid h={["100%","100%","100%"]} templateColumns={['repeat(2, 1fr)',"repeat(2, 1fr)",'repeat(5, 1fr)']} gap={["1","5","1"]}   >
//                 <GridItem w={["100%","90%","100%"]} h={["100%","100%","90%"]}>
//                 <Image
               
//                 h={"100%"}
//                 w={"100%"}
//                 src={photo1}
//                 alt='Caffe Latte'
//                 /> 
//                 </GridItem>
//                 <GridItem w={["100%","90%","100%"]} h={["100%","100%","90%"]}>
//                 <Image
               
//                 h={"100%"}
//                 w={"100%"}
//                 mt={"30px"}
//                 src={photo2}
//                 alt='Caffe Latte'
//                 /> 
//                 </GridItem>
//                 <GridItem w={["100%","90%","100%"]} h={["100%","100%","90%"]}>
//                 <Image
               
//                 h={"100%"}
//                 w={"100%"}
//                 src={photo3}
//                 alt='Caffe Latte'
//                 /> 
//                 </GridItem>
//                 <GridItem w={["100%","90%","100%"]} h={["100%","100%","90%"]}>
//                 <Image
               
//                 h={"100%"}
//                 w={"100%"}
//                 mt={"30px"}
//                 src={photo4}
//                 alt='Caffe Latte'
//                 /> 
//                 </GridItem>
//                 <GridItem w={["100%","90%","100%"]} h={["100%","100%","90%"]}>
//                 <Image
                
//                 h={"100%"}
//                 w={"100%"}
//                 src={photo5}
//                 alt='Caffe Latte'
//                 /> 
//                 </GridItem>
                
//             </Grid>
//                 </Box>
//             </Box>
           
//         </Box>
       
//     </Flex>
   
// </Box>
//   )
// }

// export default Featured
const Featured = () => {
    const settings = {
        dots: true,
    infinite: true,
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    speed: 1000, 
    autoplay: true,
    autoplaySpeed: 1000, 
    cssEase: "linear",
    pauseOnHover: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    };
  
    return (
      <Box h={["350px","650px","400px"]} w={["100%","100%","100%"]} bg={"#FFF0F3"}>
        <Flex h={["85%","85%","85%"]} w={['100%','100%','100%']} justifyContent={['center','center','center']} alignItems={['center','center','center']}>
          <Box h={'100%'} w={'100%'}>
            <Box h={"75%"} w={"100%"} textAlign={'center'}>
              <Text w={['93%','90%','46%']} h={'auto'} margin={'auto'} mt={'20px'} mb={['10px','15px','20px']} color={"#DF4646"} fontFamily={"Poppins"} fontSize={["15px","17px","17px"]}>
                To be featured tag us @abcdxyz
              </Text>
              <Box w={["100%","100%","99%"]} h={["90%","90%","100%"]} m={"auto"} mt={["25px","30px","40px"]} mb={["10px","15px","20px"]}>
                <Slider {...settings}>
                  <Image
                    h={"250px"}
                    w={"20%"}
                    src={photo1}
                    alt='Image 1'
                  />
                  <Image
                  h={"250px"}
                   w={"20%"}
                    
                    src={photo2}
                    alt='Image 2'
                  />
                  <Image
                     h={"250px"}
                     w={"20%"}
                    src={photo3}
                    alt='Image 3'
                  />
                  <Image
                    h={"250px"}
                    w={"20%"}
                   
                    src={photo4}
                    alt='Image 4'
                  />
                  <Image
                    h={"250px"}
                    w={"20%"}
                    src={photo5}
                    alt='Image 5'
                  />
                </Slider>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  };
  export default Featured
  