import React from 'react'
import {Box, Button, Flex, Heading, Input, InputGroup, InputRightAddon} from "@chakra-ui/react"
const News = () => {
  return (
    <Box h={["250px","250px","250px"]} w={["100%","100%","100%"]} bg={"#FFF0F3"}>
         <Flex h={["100%","100%","100%"]} w={["100%","100%","100%"]} justifyContent={['center','center','center']} alignItems={['center','center','center']}  >
            <Box h={['75%','75%','60%']} w={['100%','100%','40%']}  >
                <Box textAlign={['center','center','center']}>
                    <Heading as='h2' size='2xl' fontFamily={"Moniqa"} fontWeight={"none"}  letterSpacing={"8px"}>OUR <span style={{color:"#DF4646"}} >NEWSLETTER</span></Heading>
                </Box>
                <Box w={["100%","100%","100%"]} h={["60%","60%","60%"]} mt={["40px","40px","45px"]} >
                <Flex h={["100%","100%","100%"]} w={["100%","100%","100%"]} alignItems={["center","center","center"]} justifyContent={["center","center","center"]} >
                <InputGroup size='md' h={"100%"} w={"100%"} m={"auto"} ml={["2%","5%","8%"]} mr={["2%","5%",""]}>
  
               <Input placeholder='Enter your email address' border={"1px solid #EA7F91"} color={"#A7A7A7"} fontFamily={"Poppins"}/>
                     <InputRightAddon bg={"#EA7F91"} color={"white"} fontFamily={"Poppins"} cursor={"pointer"}>
                             Subscribe
                    </InputRightAddon>
                     </InputGroup>
                </Flex>
               
                </Box>
            </Box>
        </Flex>
    </Box>
  )
}

export default News