import React from 'react'
import Navbar from './Navbar/Navbar'
import "./valentines.css"
import MainNav from './Navbar/MainNav'
import Treasures from './LandingPage/Treasures'
import Brands from './LandingPage/Brands'
import Guidance from './LandingPage/Guidance'
import Affiliate from './LandingPage/Affiliate'
import News from './LandingPage/News'
import Footer1 from './LandingPage/Footer'
import Contact from './LandingPage/Contact'
import About from './LandingPage/About'
import Featured from './LandingPage/Featured'
const Valentine = () => {
  return (
    <div className='LandingContainer'>
        {/* <div className='Uppernavbar'>
            <Navbar/>
        </div>
        <div className='MainNav'>
            <MainNav/>
        </div> */}
        <div className='Treasures'>
            <Treasures/>
        </div>
        <div>
          <Brands/>
        </div>
        <div>
          <Guidance/>
        </div>
        <div>
          <Featured/>
        </div>
        <div>
          <About/>
        </div>
        <div>
          <Affiliate/>
        </div>
      
        <div>
          <Contact/>
        </div>
        <div>
          <News/>
        </div>
        <div>
          <Footer1/>
        </div>
    </div>
  )
}

export default Valentine