import { Box, Button, Flex, Heading, Image, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import photo from "./../../assets/heroPhoto.png"
const Treasures = () => {
  return (
    <Box h={["600px","600px",'600px']} w={['100%','100%','100%']} bg={"#FFB2B2"}>
        <Flex h={['100%','100%','100%']} w={['100%','100%','100%']} flexDirection={['column','column','row']}>
            <Box h={['100%','100%','100%']} w={['100%','100%','50%']}display={['flex','flex','flex']} mt={['-40px','-40px','']} justifyContent={['space-around','space-around','space-around']} alignItems={['center','center','center']} >
                <Box h={['50%','50%','75%']} w={['75%','75%','75%']}  >
                    <Box h={['10%','10%','10%']} w={["100%","100%","55%"]}  color={"#FFFFFF"}>
                        <Flex h={['100%','100%','100%']} w={['100%','100%','100']} alignItems={['center','center','center']}>
                            <Button  w={['20%','15%','25%']} h={['100%','30px','100%']} border={'1px solid white'} fontFamily={"Poppins"} marginRight={'10px'} textAlign={['center','center','center']} borderRadius={'30'} bg={'none'} color={'white'} fontSize={['12px','14px','16px']}>NEW</Button>
                            
                            <Text fontFamily={"Poppins"} fontSize={['12px','14px','16px']}>ETERNAL ELEGANCE GIFTS</Text>
                        </Flex>
                        
                    </Box>
                    <Box h={['auto','auto','auto']} w={['100%','100%','100%']} mt={["20px","20px","15px"]} mb={['15px','5px','30px']}>
                        <Heading as='h1' size={['2xl','3xl','4xl']} noOfLines={1} color={'#FFFFFF'} fontFamily={"Playfair Display"}>Unlock love’s</Heading>
                        <Heading as='h1' size={['2xl','3xl','4xl']} noOfLines={1}  color={'#DF4646'} fontFamily={"Playfair Display"} >Treasure With</Heading>
                        <Heading as='h1' size={['2xl','3xl','4xl']} noOfLines={1}  color={'#FFFFFF'} fontFamily={"Playfair Display"}>Our Perfect</Heading>
                        <Heading as='h1' size={['2xl','xl','4xl']} noOfLines={1}  color={'#DF4646'} fontFamily={"Playfair Display"}>Picks!</Heading>
                    </Box>
                    <Text color={"#DF4646"} fontFamily={"Poppins"} fontSize={['12px','14px','16px']}>OUR THE COMPANY NAME</Text>
                </Box>
            </Box>
           
            <Box h={['50%','50%','100%']} w={['100%','100%','50%']} position="relative" display="flex">
      <Image
        objectFit='cover'
        h={"100%"}
        w={"100%"}
        src={photo}
        alt='Caffe Latte'
      />
      <Text
      fontSize={["13px","13px","16px"]}
      
      position={['absolute','absolute','absolute']}
      top={["5%","5%","20%"]}
      left={["0","0","0"]}
      
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
         
          writingMode: 'vertical-rl', // Set vertical writing mode
          textOrientation: 'mixed', // Ensure text orientation works in various browsers
          padding: '16px', // Adjust padding as needed
          color: '#DF4646', // text color
         
        }}
      >
         Your ultimate destination for heartfelt gifts
      </Text>
    </Box>
        </Flex>
    </Box>

  )
}

export default Treasures