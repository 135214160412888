import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import CenterMode from './Slick'
import ImageSlider from './Slick'

const Brands = () => {
  return (
   <Box h={["550px","550px","500px"]} w={["100%","100%","100%"]} bg={"#FFF0F3"} id='brand' >
        <Flex h="100%" w={'100%'} justifyContent={'center'} alignItems={'center'}  >
            <Box h={['85%','85%','85%']} w={['100%','100%','100%']}>
                <Box textAlign={'center'}>
                    <Heading as='h2' size='xl' fontFamily={"Moniqa"} fontWeight={"none"}  letterSpacing={"8px"}>OUR <span style={{color:"#DF4646"}}>BRANDS</span></Heading>
                    <Text w={['78%','78%','48%']} h={'auto'} margin={'auto'} mt={['15px','15px','20px']} mb={'20px'} color={"#222222"} fontSize={["15px","17px","17px"]} fontFamily={"Poppins"} >
                    Discover love’s bloom! explore our enchanting valentine’s day gifts and create timeless moments.
                    click to call now and let us help you find the perfect expression of love
                    </Text>
                </Box>
                <ImageSlider/>
            </Box>
            
            <Box>
            
            </Box>
        </Flex>
   </Box>
  )
}

export default Brands